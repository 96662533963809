@font-face {
  font-family: Inter;
  src: url("./assets//fonts/inter.ttf");
}
@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 400; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 500; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 700; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Satoshi";
  font-style: normal;
  font-weight: 300; /* or font-size: normal; */
  src: url("./assets/fonts/Satoshi-Light.otf") format("opentype");
}
@font-face {
  font-family: Karla;
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/Karla-Regular.ttf");
}
@font-face {
  font-family: Karla;
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/Karla-Medium.ttf");
}
@font-face {
  font-family: Karla;
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/Karla-Medium.ttf");
}
@font-face {
  font-family: Karla;
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/Karla-Bold.ttf");
}
@font-face {
  font-family: Karla;
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/Karla-Light.ttf");
}
@font-face {
  font-family: Kum;
  font-weight: 400;
  font-style: normal;
  src: url("./assets/fonts/KumbhSans-Regular.ttf");
}
@font-face {
  font-family: Kum;
  font-weight: 500;
  font-style: normal;
  src: url("./assets/fonts/KumbhSans-Medium.ttf");
}
@font-face {
  font-family: Kum;
  font-weight: 600;
  font-style: normal;
  src: url("./assets/fonts/KumbhSans-SemiBold.ttf");
}
@font-face {
  font-family: Kum;
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/KumbhSans-Bold.ttf");
}
@font-face {
  font-family: Kum;
  font-weight: 300;
  font-style: normal;
  src: url("./assets/fonts/KumbhSans-Light.ttf");
}
