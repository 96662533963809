@import url("typography.css");
.inter {
  font-family: Inter;
}
.satoshi {
  font-family: Satoshi;
}
.hero {
  background: rgb(16, 15, 87);
  background: linear-gradient(
      11deg,
      rgba(16, 15, 87, 0.8250875350140056) 71%,
      rgba(72, 18, 122, 0.873546918767507) 100%
    ),
    url("./assets/herobg.png");
}
.about {
  background: rgba(34, 66, 180, 1);
  background: linear-gradient(
      102deg,
      rgba(34, 66, 180, 0.9) 0%,
      rgba(34, 66, 180, 0.7) 100%
    ),
    url("./assets/aboutbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contact {
  background: linear-gradient(
      102deg,
      rgba(4, 3, 46, 0.9) 800%,
      rgba(34, 66, 180, 0.9) 20%
    ),
    url("./assets/contactbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.event {
  background: linear-gradient(
      102deg,
      rgba(16, 15, 87, 0.8920343137254902) 100%,
      rgba(34, 66, 180, 0.9) 0%
    ),
    url("./assets/evemtsbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.faq {
  background: linear-gradient(
      102deg,
      rgba(17, 17, 56, 0.5) 0%,
      rgba(26, 51, 138, 0.1) 100%
    ),
    url("./assets/faqbg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.karla {
  font-family: Karla;
}
.kum {
  font-family: Kum;
}
.testimonial {
  background: linear-gradient(
      11deg,
      rgba(28, 28, 30, 0.8166841736694677) 84%,
      rgba(255, 255, 255, 1) 100%
    ),
    url("./assets/testimonial.png");
  background-position: center;
  background-size: cover;
}
